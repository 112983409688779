import React from "react";
import { Dropdown } from 'primereact/dropdown';

const DoSelectField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : '';
    const optionLabel = props.optionLabel;
    const name = props.name;
    const options = props.options;
    const placeholder = props.placeholder;
    let field = props.field;

    return (
        <div className="flex flex-column" style={{ marginLeft: "20px" }}>
            <label htmlFor={id} className="text-capitalize">{label}</label>
            <Dropdown
                value={field.value}
                optionLabel={optionLabel}
                placeholder={placeholder}
                name={name}
                options={options}
                focusInputRef={field.ref}
                onChange={(e) => field.onChange(e.value)}

            />
            {props.fieldState.invalid ? props.errors[props.name]?.message : ''}
        </div>
    )

}

export default DoSelectField;