import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Attendences = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Attendences");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "DD-MM-YYYY",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "DD-MM-YYYY",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "employeeName",
        type: "text",
        placeholder: "Employee Name",
        label: "Employee Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Employee Name",
        derivedValue:
          "employeeName=employeeName=employeeName=employeeName=EmployeeName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employeeName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "employeeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "Work Type",
        label: "Work Type",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Work Type",
        derivedValue: "type=type=type=WorkType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        filterElement: [
          { label: "Regular", value: "Regular" },
          { label: "Client Location", value: "Client Location" },
          { label: "Work From Home", value: "Work From Home" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "date",
        type: "date",
        placeholder: "Date",
        label: "Date",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Date",
        derivedValue: "date=date=Date=Date=Date=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "inTime",
        type: "String",
        placeholder: "InTime",
        label: "In Time",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "In Time",
        derivedValue: "inTime=InTime=InTime=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "inTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "inTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "outTime",
        type: "String",
        placeholder: "OutTime",
        label: "Out Time",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Out Time",
        derivedValue: "outTime=OutTime=OutTime=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "outTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "outTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "checkouts",
        type: "number",
        placeholder: "NoOfCheckouts",
        label: "No Of Checkouts",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "No Of Checkouts",
        derivedValue:
          "checkouts=checkouts=NoofCheckouts=NoofCheckouts=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "checkouts",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "checkouts",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "workingHours",
        type: "number",
        placeholder: "Working Hours",
        label: "Working Hours",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Working Hours",
        derivedValue: "workingHours=WorkingHours=WorkingHours=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "workingHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "workingHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "punchRecords",
        type: "textarea",
        placeholder: "Punch Records",
        label: "Punch Records",
        width: "200px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Punch Records",
        derivedValue: "punchRecords=PunchRecords=PunchRecords=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "punchRecords",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        style: { width: '300px', textOverflow: "ellipsis" },
        show: true,
        field: "punchRecords",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "timesheetHours",
        type: "number",
        placeholder: "Timesheet Hours",
        label: "Timesheet Hours",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Timesheet Hours",
        derivedValue:
          "timesheetHours=timesheetHours=timesheetHours=timesheetHours=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "timesheetHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "timesheetHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        type: "uniqueID",
        placeholder: "Employee Id",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Employee Id",
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "12",
        textAlign: "Center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      }
    ];
    return data;
  };

  const getFormFields = () => {

    return [
      {
        name: "employeeName",
        type: "text",
        placeholder: "Employee Name",
        label: "Employee Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue:
          "employeeName=employeeName=employeeName=employeeName=EmployeeName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employeeName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "date",
        type: "date",
        placeholder: "Date",
        label: "Date",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "date=date=Date=Date=Date=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "inTime",
        type: "String",
        placeholder: "InTime",
        label: "InTime",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "inTime=InTime=InTime=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "inTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "outTime",
        type: "String",
        placeholder: "OutTime",
        label: "OutTime",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "outTime=OutTime=OutTime=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "outTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "comments",
        type: "textarea",
        placeholder: "comments",
        label: "Comments",
        width: "200px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Comments",
        derivedValue: "comments=Comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        style: { width: '500px', textOverflow: "ellipsis" },
        show: true,
        filter: true,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };

  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "objId",
        type: "uniqueID",
        placeholder: "ObjId",
        label: "ObjId",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "ObjId",
        derivedValue: "objId=objId=objId=companyEmpId=EmployeeID=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "objId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "30",
        textAlign: "Center",
        show: false,
        field: "objId",
        showOrHideFields: [],
        fieldName: "objId",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employeeName",
        type: "text",
        placeholder: "Employee Name",
        label: "Employee Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Employee Name",
        derivedValue:
          "employeeName=employeeName=employeeName=employeeName=EmployeeName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employeeName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "employeeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "Work Type",
        label: "Work Type",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Work Type",
        derivedValue: "type=type=type=WorkType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Permanent", value: "Permanent", color: "primary" },
          { label: "Contract", value: "Contract", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "date",
        type: "date",
        placeholder: "Date",
        label: "Date",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Date",
        derivedValue: "date=date=Date=Date=Date=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "inTime",
        type: "time",
        placeholder: "InTime",
        label: "InTime",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "InTime",
        derivedValue: "inTime=InTime=InTime=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "inTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "inTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "outTime",
        type: "time",
        placeholder: "OutTime",
        label: "OutTime",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "OutTime",
        derivedValue: "outTime=OutTime=OutTime=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "outTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "outTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "checkouts",
        type: "number",
        placeholder: "NoOfCheckouts",
        label: "NoOfCheckouts",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "NoOfCheckouts",
        derivedValue:
          "checkouts=checkouts=NoofCheckouts=NoofCheckouts=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "checkouts",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "checkouts",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "workingHours",
        type: "number",
        placeholder: "Working Hours",
        label: "Working Hours",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Working Hours",
        derivedValue: "workingHours=WorkingHours=WorkingHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "workingHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "workingHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "punchRecords",
        type: "textarea",
        placeholder: "Punch Records",
        label: "Punch Records",
        width: "200px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Punch Records",
        derivedValue: "punchRecords=PunchRecords=PunchRecords=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "punchRecords",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "punchRecords",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "timesheetHours",
        type: "number",
        placeholder: "Timesheet Hours",
        label: "Timesheet Hours",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Timesheet Hours",
        derivedValue:
          "timesheetHours=timesheetHours=timesheetHours=timesheetHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "timesheetHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "timesheetHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        type: "uniqueID",
        placeholder: "Employee Id",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Employee Id",
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "12",
        textAlign: "Center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "filterDate",
        type: "date",
        placeholder: "FilterDate",
        label: "FilterDate",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "FilterDate",
        derivedValue: "filterDate=filterDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "filterDate",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD",
        fieldType: "Date",
        textAlign: "Center",
        show: false,
        field: "filterDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "isLessThan6Hours",
        type: "number",
        placeholder: "IsLessThan6Hours",
        label: "IsLessThan6Hours",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "IsLessThan6Hours",
        derivedValue: "isLessThan6Hours=isLessThan6Hours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "isLessThan6Hours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "isLessThan6Hours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "isWeekEnd",
        type: "text",
        placeholder: "IsWeekEnd",
        label: "IsWeekEnd",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "IsWeekEnd",
        derivedValue: "isWeekEnd=isWeekEnd=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "isWeekEnd",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "isWeekEnd",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "role",
        type: "text",
        placeholder: "Role",
        label: "Role",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "Role",
        derivedValue: "role=role=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "role",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "role",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "wfhApproved",
        type: "radio",
        placeholder: "WfhApproved",
        label: "WfhApproved",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "WfhApproved",
        derivedValue: "wfhApproved=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "wfhApproved",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "radio",
        textAlign: "Center",
        show: false,
        field: "wfhApproved",
        showOrHideFields: [],
        fieldName: "wfhApproved",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "WFH",
        type: "radio",
        placeholder: "WFH",
        label: "WFH",
        width: "120px",
        addFormOrder: 24,
        editFormOrder: 24,
        header: "WFH",
        derivedValue: "WFH=WFH=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "WFH",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "radio",
        textAlign: "Center",
        show: false,
        field: "WFH",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "checkOutMissed",
        type: "radio",
        placeholder: "CheckOutMissed",
        label: "CheckOutMissed",
        width: "120px",
        addFormOrder: 25,
        editFormOrder: 25,
        header: "CheckOutMissed",
        derivedValue: "checkOutMissed=checkOutMissed=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "checkOutMissed",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "radio",
        textAlign: "Center",
        show: false,
        field: "checkOutMissed",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromBulk",
        type: "text",
        placeholder: "FromBulk",
        label: "FromBulk",
        width: "130px",
        addFormOrder: 26,
        editFormOrder: 26,
        header: "FromBulk",
        derivedValue: "fromBulk=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "fromBulk",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "fromBulk",
        showOrHideFields: [],
        fieldName: "fromBulk",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

  };

  const saveDataToServer = async (item, field, value) => {
    let body = item[0];
    body[field] = value;
    let userBody = Object.assign({}, body);
    if (body) {
      let method, apiUrl;
      method = "PUT";
      apiUrl = `${apiCalls.attendences}/${body._id}`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ]

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          // addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          PrintRequired={isEditPermission}
          printRequried={true}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.attendences}
          globalSearch={"employeeName/type"}
          displayName="Attendences"
          type="Attendences"
          routeTo={apiCalls.attendences}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          colWidth="narrow"
          apiResponseKey={apiCalls.attendences.toLowerCase()}
          apiUrl={apiCalls.attendences}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="attendences"
          apiUrl={apiCalls.attendences}
        />
      ) : null}
    </span>
  );
};

export default Attendences;