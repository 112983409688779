import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import LeaveBalanceModal from "../../CommonModals/LeaveBalanceModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
// config file

const Employees = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [rolesList, setRolesList] = useState([]);
  const [gotRoles, setgotRoles] = useState(false);
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [isOpenLeaveBalanceModal, setIsOpenLeaveBalanceModal] = useState(false)
  const [leaveData, setLeaveData] = useState({})

  let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))

  const params = useParams();

  useEffect(() => {
    getRoleListFromServer();
    let screenPermissions = RolePermissions.screenPermissions("Employees");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getRoleListFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.direction = "asc";
    url = `roles?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.roles && res.roles.length > 0) {
          let rolesList = [];
          for (let obj of res.roles) {
            if (obj.role) rolesList.push({ value: obj.role, label: obj.role });
          }
          setRolesList(rolesList);
          setgotRoles(true);
        }
      })
      .catch((err) => {
        setgotRoles(true);
        return err;
      });
  };

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        displayinregisterForm: "true",
        disabled: true,
        globalSearchField: "true",
        show: true,
        field: "displayName",
        addFormOrder: 10,
        editFormOrder: 1,
        fieldName: "name",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        sortable: true,
        filter: true,
        textAlign: "Center",
        displayinregisterForm: "true",
        disabled: true,
        show: true,
        globalSearchField: "true",
        field: "email",
        header: "Email",
        addFormOrder: 4,
        editFormOrder: 2,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        header: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        sortable: true,
        filter: true,
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        filterElement: rolesList,
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "role",
        mobile: true,
        globalSearchField: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        addFormOrder: 3,
        editFormOrder: 4,
      },
      {
        name: "reportingToName",
        type: "relateAutoComplete",
        placeholder: "ReportingTo",
        label: "ReportingTo",
        header: "ReportingTo",
        derivedValue: "reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        sortable: true,
        filter: true,
        id: "reportingToName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        controllerId: 1001,
        searchApi: "employees",
        width: 110,
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "reportingTo",
        addFormOrder: 7,
        editFormOrder: 6,
        fieldName: "reportingTo",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        sortable: true,
        filter: true,
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "DD-MM-YYYY",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        addFormOrder: 10,
        editFormOrder: 10,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        sortable: true,
        filter: true,
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "DD-MM-YYYY",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        addFormOrder: 11,
        editFormOrder: 11,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "DOB",
        type: "date",
        label: "Date Of Birth",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 12,
        header: "Date Of Birth",
        derivedValue: "DOB=DOB=DOB=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DOB",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "dateOfBirth",
        showOrHideFields: [],
        fieldName: "DOB",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "DateOfJoin",
        type: "date",
        placeholder: "Date Of Join",
        label: "Date Of Join",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 13,
        header: "Date Of Join",
        derivedValue: "DateOfJoin=DateOfJoin=DateOfJoin=DateOfJoin=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DateOfJoin",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "joinDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "CompanyEmail",
        type: "email",
        placeholder: "Company Email",
        label: "Company Email",
        width: "150px",
        addFormOrder: 13,
        editFormOrder: 15,
        header: "Company Email",
        derivedValue:
          "CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "CompanyEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "companyEmail",
        showOrHideFields: [],
        fieldName: "CompanyEmail",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "AadharNo",
        type: "text",
        placeholder: "Aadhar No",
        label: "Aadhar No",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 10,
        header: "Aadhar No",
        derivedValue:
          "AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "AadharNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{0,12}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "12",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "aadharcardNumber",
        showOrHideFields: [],
        fieldName: "AadharNo",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "EmployeeId",
        type: "uniqueField",
        placeholder: "Employee Id",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 18,
        header: "Employee Id",
        derivedValue: "EmployeeId=EmployeeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmployeeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "employeeId",
        showOrHideFields: [],
        fieldName: "EmployeeId",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "BiometricId",
        type: "uniqueField",
        placeholder: "Biometric Id",
        label: "Biometric Id",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 19,
        header: "Biometric Id",
        derivedValue: "BiometricId=BiometricId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BiometricId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "biometricId",
        showOrHideFields: [],
        fieldName: "BiometricId",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "PhoneNumber",
        type: "text",
        placeholder: "Phone Number",
        label: "Phone Number",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 20,
        header: "Phone Number",
        derivedValue:
          "PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{0,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "EmergencyContact",
        type: "text",
        label: "Emergency Contact",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 21,
        header: "Emergency Contact",
        derivedValue:
          "EmergencyContact=EmergencyContact=EmergencyContact=EmergencyContact=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmergencyContact",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{0,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "emergencyContact",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "SkypeId",
        type: "uniqueField",
        placeholder: "Skype Id",
        label: "Skype Id",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 22,
        header: "Skype Id",
        derivedValue: "SkypeId=SkypeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "SkypeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "skypeId",
        showOrHideFields: [],
        fieldName: "SkypeId",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "BitBucketId",
        type: "uniqueField",
        placeholder: "Bit Bucket Id",
        label: "Bit Bucket Id",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 23,
        header: "Bit Bucket Id",
        derivedValue: "BitBucketId=BitBucketId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BitBucketId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "bitbuketId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Qualification",
        type: "text",
        label: "Qualification",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 24,
        header: "Qualification",
        derivedValue: "Qualification=Qualification=Qualification=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Qualification",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "qualification",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "document",
        type: "dropDown",
        placeholder: "document",
        label: "Document",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 28,
        header: "document",
        derivedValue: "document=document=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "document",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        filterElement: [
          { label: '10th Certificate', value: '10th Certificate', color: "info" },
          { label: 'Inter', value: 'Inter', color: 'primary' },
          { label: 'Diploma', value: 'Diploma', color: "secondary" },
          { label: 'Degree', value: 'Degree', color: "warning" },
          { label: 'BE/B-Tech', value: 'BE/B-Tech', color: "success" },
          { label: 'MBA', value: 'MBA', color: 'success' },
          { label: 'ME/M-Tech', value: 'ME/M-Tech', color: "danger" },
          { label: 'MCA', value: 'MCA', color: 'dark' },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "document",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isMultiSelect: true
      },
      {
        name: "Consultancy",
        type: "text",
        label: "Consultancy",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 25,
        header: "Consultancy",
        derivedValue: "Consultancy=Consultancy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Consultancy",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "consultancy",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "PreviousExperience",
        type: "text",
        placeholder: "Previous Experience",
        label: "Previous Experience",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 26,
        header: "Previous Experience",
        derivedValue:
          "PreviousExperience=PreviousExperience=PreviousExperience=PreviousExperience=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PreviousExperience",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "previousExperience",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "previousCompany",
        type: "text",
        placeholder: "Previous Company",
        label: "Previous Company",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 27,
        header: "Previous Company",
        derivedValue:
          "previousCompany=previousCompany=previousCompany=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "previousCompany",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "previousCompany",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "EmploymentType",
        type: "dropDown",
        placeholder: "Employment Type",
        label: "Employment Type",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 28,
        header: "Employment Type",
        derivedValue: "EmploymentType=EmploymentType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmploymentType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        filterElement: [
          { label: "Permanent", value: "Permanent", color: "success" },
          { label: "Contract", value: "Contract", color: "primary" },
        ],
        options: [
          { label: "Permanent", value: "Permanent", color: "primary" },
          { label: "Contract", value: "Contract", color: "success" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "employmentType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Gender",
        type: "radio",
        placeholder: "Gender",
        value: "Female",
        label: "Gender",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 31,
        header: "Gender",
        derivedValue: "Gender=Gender=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Gender",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        filterElement: [
          { label: "Female", value: "Female" },
          { label: "Male", value: "Male" },
        ],
        fieldType: "radio",
        textAlign: "Center",
        show: true,
        field: "gender",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "TemporaryAddress",
        type: "textarea",
        placeholder: "Temporary Address",
        label: "Temporary Address",
        width: "200px",
        addFormOrder: 27,
        editFormOrder: 32,
        header: "Temporary Address",
        derivedValue: "TemporaryAddress=TemporaryAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "TemporaryAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        style: { width: '250px', textOverflow: "ellipsis" },
        show: true,
        field: "temporaryAddress",
        showOrHideFields: [],
        fieldName: "TemporaryAddress",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "PermanentAddress",
        type: "textarea",
        placeholder: "Permanent Address",
        label: "Permanent Address",
        width: "200px",
        addFormOrder: 28,
        editFormOrder: 33,
        header: "Permanent Address",
        derivedValue: "PermanentAddress=PermanentAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PermanentAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        style: { width: '250px', textOverflow: "ellipsis" },
        show: true,
        field: "permanentAddress",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "BankAccountNo",
        type: "text",
        placeholder: "Bank Account No",
        label: "Bank Account No",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 34,
        header: "Bank Account No",
        derivedValue:
          "BankAccountNo=BankAccountNo=BankAccountNo=BankAccountNo=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BankAccountNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^.{0,10000}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: false,
          showNumField: false,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "accountNo",
        showOrHideFields: [],
        fieldName: "BankAccountNo",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "UANNumber",
        type: "uniqueField",
        placeholder: "UAN Number",
        label: "UAN Number",
        width: "120px",
        addFormOrder: 30,
        editFormOrder: 35,
        header: "UAN Number",
        derivedValue: "UANNumber=UANNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "UANNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "uanNumber",
        showOrHideFields: [],
        fieldName: "UANNumber",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "IFSCCode",
        type: "uniqueField",
        placeholder: "IFSC Code",
        label: "IFSC Code",
        width: "120px",
        addFormOrder: 31,
        editFormOrder: 36,
        header: "IFSC Code",
        derivedValue: "IFSCCode=IFSCCode=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "IFSCCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "ifscCode",
        showOrHideFields: [],
        fieldName: "IFSCCode",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "EmployeeDocumentDriveLink",
        type: "textarea",
        placeholder: "Employee Document Drive Link",
        label: "Employee Document Drive Link",
        width: "200px",
        addFormOrder: 33,
        editFormOrder: 37,
        header: "Employee Document Drive Link",
        derivedValue:
          "EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "EmployeeDocumentDriveLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        fieldType: "driveLink",
        field: "employeeDocumentDriveLinks",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "IdCardReceived",
        type: "radio",
        placeholder: "Id Card Received ?",
        label: "Id Card Received ?",
        width: "120px",
        addFormOrder: 35,
        editFormOrder: 38,
        header: "Id Card Received ?",
        derivedValue: "IdCardReceived=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "IdCardReceived",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        textAlign: "Center",
        show: true,
        field: "idCardReceived",
        showOrHideFields: [],
        label0: "Yes",
        value0: "Yes",
        label1: "No",
        value1: "No",
        fieldName: "IdCardReceived",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Orientation_date",
        type: "date",
        placeholder: "Orientation Date",
        label: "Orientation Date",
        width: "130px",
        addFormOrder: 32,
        editFormOrder: 36,
        header: "Orientation Date",
        derivedValue: "Orientation_date=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Orientation_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "orientationDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Termination_date",
        type: "date",
        placeholder: "Termination Date",
        label: "Termination Date",
        width: "130px",
        addFormOrder: 34,
        editFormOrder: 37,
        header: "Termination Date",
        derivedValue: "Termination_date=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Termination_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "terminationDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Bank_name",
        type: "text",
        placeholder: "Bank Name",
        label: "Bank Name",
        width: "130px",
        addFormOrder: 37,
        editFormOrder: 41,
        header: "Bank Name",
        derivedValue: "Bank_name=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Bank_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "bankName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "BranchName",
        type: "text",
        placeholder: "Branch Name",
        label: "Branch Name",
        width: "130px",
        addFormOrder: 38,
        editFormOrder: 42,
        header: "Branch Name",
        derivedValue: "BranchName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BranchName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "branch",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "image",
        type: "profile",
        placeholder: "Attach File",
        label: "Attach File",
        width: "120px",
        addFormOrder: 39,
        editFormOrder: 43,
        header: "Attach File",
        derivedValue: "attach_file=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "employee",
        textAlign: "Center",
        show: true,
        fieldType: "image",
        field: "image",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "totalLeaves",
        type: "text",
        placeholder: "Total Leaves",
        label: "Total Leaves",
        header: "Total Leaves",
        width: 60,
        id: "totalLeaves",
        displayinlist: "true",
        sortable: true,
        filter: true,
        textAlign: "Center",
        displayinregisterForm: "true",
        disabled: true,
        globalSearchField: "true",
        show: true,
        field: "totalLeaves",
        fieldName: "totalLeaves",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 40,
        editFormOrder: 44,
        header: "Status",
        derivedValue: "Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        // defaultValues: ['Active', 'Pending'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
      },
      {
        name: "pancardNumber",
        type: "text",
        placeholder: "PAN",
        label: "PAN",
        width: "130px",
        addFormOrder: 43,
        editFormOrder: 43,
        header: "PAN",
        derivedValue: "pan=pan=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "pan",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[a-zA-Z0-9]{0,10}$",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: true,
          showAlphaField: true,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "pancardNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {

    return [
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        displayinregisterForm: "true",
        validationRequired: true,
        regexPattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$",
        isFieldRequired: "true",
        required: true,
        disabled: true,
        show: true,
        globalSearchField: "true",
        addFormOrder: 5,
        editFormOrder: 5,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        controllerName: null,
        options: [],
        show: true,
        disabled: true,
        mobile: true,
        globalSearchField: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        addFormOrder: 21,
        editFormOrder: 22,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "reportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo",
        label: "Reporting To",
        derivedValue: "reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        controllerId: 1001,
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        width: 110,
        show: true,
        disabled: true,
        addFormOrder: 22,
        editFormOrder: 23,
        fieldName: "reportingTo",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "hrReportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo HR",
        label: "Reporting To HR",
        derivedValue: "reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        controllerId: 1001,
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        width: 110,
        show: true,
        disabled: true,
        addFormOrder: 22,
        editFormOrder: 23,
        fieldName: "hrReportingTo",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        addFormOrder: 10,
        editFormOrder: 10,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        addFormOrder: 11,
        editFormOrder: 11,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
        label: "First Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue:
          "FirstName=FirstName=FirstName=FirstName=FirstName=FirstName=FirstName=FirstName=undefined",
        capitalizeTableText: true,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "FirstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        label: "Last Name",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "LastName=LastName=LastName=LastName=undefined",
        capitalizeTableText: true,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "LastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "dateOfBirth",
        type: "date",
        label: "Date Of Birth",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "DOB=DOB=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DOB",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        fieldName: "dateOfBirth",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "joinDate",
        type: "date",
        placeholder: "Date Of Join",
        label: "Date Of Join",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "DateOfJoin=DateOfJoin=DateOfJoin=DateOfJoin=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DateOfJoin",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "companyEmail",
        type: "email",
        placeholder: "Company Email",
        label: "Company Email",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue:
          "CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=undefined",
        capitalizeTableText: true,
        sortable: true,
        actions: [],
        actionsNumber: [],
        id: "CompanyEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "CompanyEmail",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        isAddFieldDisable: false,
        isEditFieldDisable: true,
      },
      {
        name: "pancardNumber",
        type: "uniqueField",
        placeholder: "PAN",
        label: "PAN",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "PAN=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "PAN",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: false,
        regexPattern: "^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$",
        show: true,
        showOrHideFields: [],
        fieldName: "pancardNumber",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "aadharcardNumber",
        type: "text",
        placeholder: "Aadhar No",
        label: "Aadhar No",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "AadharNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: false,
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: false,
        regexPattern: "^[0-9]{12,12}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "12",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "12",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        fieldName: "AadharNo",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "employeeId",
        type: "uniqueField",
        placeholder: "Employee Id",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "EmployeeId=EmployeeId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EmployeeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "employeeId",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "biometricId",
        type: "uniqueField",
        placeholder: "Biometric Id",
        label: "Biometric Id",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "BiometricId=BiometricId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "BiometricId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "biometricId",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phoneNumber",
        type: "text",
        placeholder: "Phone Number",
        label: "Phone Number",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 20,
        derivedValue:
          "PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{10,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "10",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "emergencyContact",
        type: "text",
        label: "Emergency Contact",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue:
          "EmergencyContact=EmergencyContact=EmergencyContact=EmergencyContact=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EmergencyContact",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{10,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "10",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        fieldName: "emergencyContact",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "skypeId",
        type: "uniqueField",
        placeholder: "Skype Id",
        label: "Skype Id",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "SkypeId=SkypeId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "SkypeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "skypeId",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "bitbuketId",
        type: "uniqueField",
        placeholder: "Bit Bucket Id",
        label: "Bit Bucket Id",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "BitBucketId=BitBucketId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "BitBucketId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "qualification",
        type: "text",
        label: "Qualification",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "Qualification=Qualification=Qualification=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Qualification",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "document",
        type: "dropDown",
        placeholder: "document",
        label: "Document",
        width: "130px",
        addFormOrder: 45,
        editFormOrder: 45,
        derivedValue: "document=document=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "document",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: '10th Certificate', value: '10th Certificate' },
          { label: 'Inter', value: 'Inter' },
          { label: 'Diploma', value: 'Diploma' },
          { label: 'Degree', value: 'Degree' },
          { label: 'BE/B-Tech', value: 'BE/B-Tech' },
          { label: 'MBA', value: 'MBA' },
          { label: 'ME/M-Tech', value: 'ME/M-Tech' },
          { label: 'MCA', value: 'MCA' },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        isMultiSelect: true
      },
      {
        name: "consultancy",
        type: "text",
        label: "Consultancy",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue: "Consultancy=Consultancy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Consultancy",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "previousExperience",
        type: "text",
        placeholder: "Previous Experience",
        label: "Previous Experience",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue:
          "PreviousExperience=PreviousExperience=PreviousExperience=PreviousExperience=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "PreviousExperience",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "previousCompany",
        type: "text",
        placeholder: "Previous Company",
        label: "Previous Company",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue:
          "PreviousCompany=PreviousCompany=PreviousCompany=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "previousCompany",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "employmentType",
        type: "dropDown",
        placeholder: "Employment Type",
        label: "Employment Type",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 21,
        derivedValue: "EmploymentType=EmploymentType=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EmploymentType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Permanent", value: "Permanent", color: "info" },
          { label: "Contract", value: "Contract", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "gender",
        type: "radio",
        placeholder: "Gender",
        label: "Gender",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 24,
        derivedValue: "Gender=Gender=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Gender",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Female", value: "Female" },
          { label: "Male", value: "Male" },
        ],
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        label0: "Female",
        value0: "Female",
        label1: "Male",
        value1: "Male",
        fieldName: "gender",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "temporaryAddress",
        type: "textarea",
        placeholder: "Temporary Address",
        label: "Temporary Address",
        width: "200px",
        addFormOrder: 27,
        editFormOrder: 25,
        derivedValue: "TemporaryAddress=TemporaryAddress=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "TemporaryAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "temporaryAddress",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "permanentAddress",
        type: "textarea",
        placeholder: "Permanent Address",
        label: "Permanent Address",
        width: "200px",
        addFormOrder: 28,
        editFormOrder: 26,
        derivedValue: "PermanentAddress=PermanentAddress=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "PermanentAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "accountNo",
        type: "text",
        placeholder: "Bank Account No",
        label: "Bank Account No",
        width: "130px",
        addFormOrder: 31,
        editFormOrder: 34,
        derivedValue:
          "BankAccountNo=BankAccountNo=BankAccountNo=BankAccountNo=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "BankAccountNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        // validationRequired: true,
        regexPattern: "^.{0,10000}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: false,
          showNumField: false,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        fieldName: "BankAccountNo",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "uanNumber",
        type: "uniqueField",
        placeholder: "UAN Number",
        label: "UAN Number",
        width: "120px",
        addFormOrder: 33,
        editFormOrder: 36,
        derivedValue: "UANNumber=UANNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "UANNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "uanNumber",
        validationRequired: false,
        regexPattern: "^[0-9]{12,12}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "12",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "12",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
        },
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "ifscCode",
        type: "uniqueField",
        placeholder: "IFSC Code",
        label: "IFSC Code",
        width: "120px",
        addFormOrder: 32,
        editFormOrder: 35,
        derivedValue: "IFSCCode=IFSCCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "IFSCCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "IFSCCode",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "employeeDocumentDriveLinks",
        type: "textarea",
        placeholder: "Employee Document Drive Link",
        label: "Employee Document Drive Link",
        width: "200px",
        addFormOrder: 33,
        editFormOrder: 38,
        derivedValue:
          "EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "EmployeeDocumentDriveLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "employeeDocumentDriveLinks",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "idCardReceived",
        type: "radio",
        placeholder: "Id Card Received ?",
        label: "Id Card Received ?",
        width: "120px",
        addFormOrder: 35,
        editFormOrder: 38,
        derivedValue: "IdCardReceived=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "IdCardReceived",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        label0: "Yes",
        value0: "Yes",
        label1: "No",
        value1: "No",
        fieldName: "idCardReceived",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "leavesAccruedBlocked",
        type: "radio",
        value: false,
        placeholder: "Leaves Accrued Blocked",
        label: "Leaves Accrued Blocked",
        width: "120px",
        addFormOrder: 35,
        editFormOrder: 38,
        derivedValue: "IdCardReceived=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "leavesAccruedBlocked",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        label0: "Yes",
        value0: "Yes",
        label1: "No",
        value1: "No",
        fieldName: "leavesAccruedBlocked",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "orientationDate",
        type: "date",
        placeholder: "Orientation Date",
        label: "Orientation Date",
        width: "130px",
        addFormOrder: 34,
        editFormOrder: 36,
        derivedValue: "Orientation_date=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Orientation_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "terminationDate",
        type: "date",
        placeholder: "Termination Date",
        label: "Termination Date",
        width: "130px",
        addFormOrder: 34,
        editFormOrder: 36,
        derivedValue: "Termination_date=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Termination_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "bankName",
        type: "text",
        placeholder: "Bank Name",
        label: "Bank Name",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 31,
        derivedValue: "Bank_name=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Bank_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "branch",
        type: "text",
        placeholder: "Branch Name",
        label: "Branch Name",
        width: "130px",
        addFormOrder: 30,
        editFormOrder: 32,
        derivedValue: "BranchName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BranchName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 40,
        editFormOrder: 42,
        derivedValue: "Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "image",
        type: "profile",
        placeholder: "Attach File",
        label: "Attach File",
        width: "120px",
        addFormOrder: 39,
        editFormOrder: 43,
        derivedValue: "attach_file=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "employee",
        show: true,
        field: "attach_file",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };

  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        displayinregisterForm: "true",
        disabled: true,
        globalSearchField: "true",
        show: true,
        field: "name",
        addFormOrder: 10,
        editFormOrder: 1,
        fieldName: "name",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        displayinregisterForm: "true",
        disabled: true,
        show: true,
        globalSearchField: "true",
        field: "email",
        header: "Email",
        addFormOrder: 4,
        editFormOrder: 2,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        id: "address",
        width: 180,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        show: true,
        disabled: true,
        globalSearchField: "true",
        field: "address",
        header: "Address",
        addFormOrder: 5,
        editFormOrder: 3,
        fieldName: "address",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        header: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        options: [],
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "role",
        mobile: true,
        globalSearchField: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        addFormOrder: 3,
        editFormOrder: 4,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        id: "phone",
        width: 110,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        displayinregisterForm: "true",
        show: true,
        disabled: true,
        field: "phone",
        header: "Phone",
        addFormOrder: 6,
        editFormOrder: 5,
        fieldName: "phone",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },
      {
        name: "reportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo",
        label: "ReportingTo",
        header: "ReportingTo",
        derivedValue: "reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        controllerId: 1001,
        searchApi: "employees",
        width: 110,
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "reportingTo",
        addFormOrder: 7,
        editFormOrder: 6,
        fieldName: "reportingTo",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "password",
        label: "password",
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "false",
        controllerName: null,
        textAlign: "Center",
        show: false,
        displayInSettings: false,
        disabled: true,
        field: "password",
        header: "password",
        addFormOrder: 8,
        editFormOrder: 7,
        mobile: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        addFormOrder: 10,
        editFormOrder: 10,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        addFormOrder: 11,
        editFormOrder: 11,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "FirstName",
        type: "text",
        value: "Ram",
        label: "First Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 10,
        header: "First Name",
        derivedValue:
          "FirstName=FirstName=FirstName=FirstName=FirstName=FirstName=FirstName=FirstName=undefined",
        capitalizeTableText: true,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "FirstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "FirstName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "LastName",
        type: "text",
        placeholder: "Last Name",
        label: "Last Name",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 11,
        header: "Last Name",
        derivedValue: "LastName=LastName=LastName=LastName=undefined",
        capitalizeTableText: true,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "LastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "LastName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "DOB",
        type: "date",
        label: "Date Of Birth",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 12,
        header: "Date Of Birth",
        derivedValue: "DOB=DOB=DOB=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DOB",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "DOB",
        showOrHideFields: [],
        fieldName: "DOB",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "DateOfJoin",
        type: "date",
        placeholder: "Date Of Join",
        label: "Date Of Join",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 13,
        header: "Date Of Join",
        derivedValue: "DateOfJoin=DateOfJoin=DateOfJoin=DateOfJoin=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "DateOfJoin",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "DateOfJoin",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "CompanyEmail",
        type: "email",
        placeholder: "Company Email",
        label: "Company Email",
        width: "150px",
        addFormOrder: 13,
        editFormOrder: 15,
        header: "Company Email",
        derivedValue:
          "CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=CompanyEmail=undefined",
        capitalizeTableText: true,
        sortable: true,
        actions: [],
        actionsNumber: [],
        id: "CompanyEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "CompanyEmail",
        showOrHideFields: [],
        fieldName: "CompanyEmail",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "AadharNo",
        type: "text",
        placeholder: "Aadhar No",
        label: "Aadhar No",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 10,
        header: "Aadhar No",
        derivedValue:
          "AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=AadharNo=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "AadharNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{0,12}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "12",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "AadharNo",
        showOrHideFields: [],
        fieldName: "AadharNo",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "EmployeeId",
        type: "uniqueField",
        placeholder: "Employee Id",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 18,
        header: "Employee Id",
        derivedValue: "EmployeeId=EmployeeId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EmployeeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "EmployeeId",
        showOrHideFields: [],
        fieldName: "EmployeeId",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "BiometricId",
        type: "uniqueField",
        placeholder: "Biometric Id",
        label: "Biometric Id",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 19,
        header: "Biometric Id",
        derivedValue: "BiometricId=BiometricId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "BiometricId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "BiometricId",
        showOrHideFields: [],
        fieldName: "BiometricId",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "PhoneNumber",
        type: "text",
        placeholder: "Phone Number",
        label: "Phone Number",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 20,
        header: "Phone Number",
        derivedValue:
          "PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=PhoneNumber=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{0,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "PhoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "EmergencyContact",
        type: "text",
        label: "Emergency Contact",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 21,
        header: "Emergency Contact",
        derivedValue:
          "EmergencyContact=EmergencyContact=EmergencyContact=EmergencyContact=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EmergencyContact",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{0,10}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "EmergencyContact",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "SkypeId",
        type: "uniqueField",
        placeholder: "Skype Id",
        label: "Skype Id",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 22,
        header: "Skype Id",
        derivedValue: "SkypeId=SkypeId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "SkypeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "SkypeId",
        showOrHideFields: [],
        fieldName: "SkypeId",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "BitBucketId",
        type: "uniqueField",
        placeholder: "Bit Bucket Id",
        label: "Bit Bucket Id",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 23,
        header: "Bit Bucket Id",
        derivedValue: "BitBucketId=BitBucketId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "BitBucketId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "BitBucketId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Qualification",
        type: "text",
        label: "Qualification",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 24,
        header: "Qualification",
        derivedValue: "Qualification=Qualification=Qualification=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Qualification",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Qualification",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Consultancy",
        type: "text",
        label: "Consultancy",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 25,
        header: "Consultancy",
        derivedValue: "Consultancy=Consultancy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Consultancy",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Consultancy",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "PreviousExperience",
        type: "text",
        placeholder: "Previous Experience",
        label: "Previous Experience",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 26,
        header: "Previous Experience",
        derivedValue:
          "PreviousExperience=PreviousExperience=PreviousExperience=PreviousExperience=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "PreviousExperience",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "PreviousExperience",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "PreviousCompany",
        type: "text",
        placeholder: "Previous Company",
        label: "Previous Company",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 27,
        header: "Previous Company",
        derivedValue:
          "PreviousCompany=PreviousCompany=PreviousCompany=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PreviousCompany",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "PreviousCompany",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "EmploymentType",
        type: "dropDown",
        placeholder: "Employment Type",
        label: "Employment Type",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 28,
        header: "Employment Type",
        derivedValue: "EmploymentType=EmploymentType=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EmploymentType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Permanent", value: "Permanent", color: "primary" },
          { label: "Contract", value: "Contract", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "EmploymentType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Gender",
        type: "radio",
        placeholder: "Gender",
        value: "Female",
        label: "Gender",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 31,
        header: "Gender",
        derivedValue: "Gender=Gender=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Gender",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Female", value: "Female" },
          { label: "Male", value: "Male" },
        ],
        fieldType: "radio",
        textAlign: "Center",
        show: true,
        field: "Gender",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "TemporaryAddress",
        type: "textarea",
        placeholder: "Temporary Address",
        label: "Temporary Address",
        width: "200px",
        addFormOrder: 27,
        editFormOrder: 32,
        header: "Temporary Address",
        derivedValue: "TemporaryAddress=TemporaryAddress=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "TemporaryAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "TemporaryAddress",
        showOrHideFields: [],
        fieldName: "TemporaryAddress",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "PermanentAddress",
        type: "textarea",
        placeholder: "Permanent Address",
        label: "Permanent Address",
        width: "200px",
        addFormOrder: 28,
        editFormOrder: 33,
        header: "Permanent Address",
        derivedValue: "PermanentAddress=PermanentAddress=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "PermanentAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "PermanentAddress",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "BankAccountNo",
        type: "text",
        placeholder: "Bank Account No",
        label: "Bank Account No",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 34,
        header: "Bank Account No",
        derivedValue:
          "BankAccountNo=BankAccountNo=BankAccountNo=BankAccountNo=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "BankAccountNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^.{0,10000}$",
        regexData: {
          showMinField: true,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: false,
          showNumField: false,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "BankAccountNo",
        showOrHideFields: [],
        fieldName: "BankAccountNo",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "UANNumber",
        type: "uniqueField",
        placeholder: "UAN Number",
        label: "UAN Number",
        width: "120px",
        addFormOrder: 30,
        editFormOrder: 35,
        header: "UAN Number",
        derivedValue: "UANNumber=UANNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "UANNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "UANNumber",
        showOrHideFields: [],
        fieldName: "UANNumber",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "IFSCCode",
        type: "uniqueField",
        placeholder: "IFSC Code",
        label: "IFSC Code",
        width: "120px",
        addFormOrder: 31,
        editFormOrder: 36,
        header: "IFSC Code",
        derivedValue: "IFSCCode=IFSCCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "IFSCCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "IFSCCode",
        showOrHideFields: [],
        fieldName: "IFSCCode",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "EmployeeDocumentDriveLink",
        type: "textarea",
        placeholder: "Employee Document Drive Link",
        label: "Employee Document Drive Link",
        width: "200px",
        addFormOrder: 33,
        editFormOrder: 37,
        header: "Employee Document Drive Link",
        derivedValue:
          "EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=EmployeeDocumentDriveLink=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "EmployeeDocumentDriveLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "EmployeeDocumentDriveLink",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "IdCardReceived",
        type: "radio",
        placeholder: "Id Card Received ?",
        label: "Id Card Received ?",
        width: "120px",
        addFormOrder: 35,
        editFormOrder: 38,
        header: "Id Card Received ?",
        derivedValue: "IdCardReceived=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "IdCardReceived",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        textAlign: "Center",
        show: true,
        field: "IdCardReceived",
        showOrHideFields: [],
        label0: "Yes",
        value0: "Yes",
        label1: "No",
        value1: "No",
        fieldName: "IdCardReceived",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Orientation_date",
        type: "date",
        placeholder: "Orientation Date",
        label: "Orientation Date",
        width: "130px",
        addFormOrder: 32,
        editFormOrder: 36,
        header: "Orientation Date",
        derivedValue: "Orientation_date=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Orientation_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "Orientation_date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Termination_date",
        type: "date",
        placeholder: "Termination Date",
        label: "Termination Date",
        width: "130px",
        addFormOrder: 34,
        editFormOrder: 37,
        header: "Termination Date",
        derivedValue: "Termination_date=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Termination_date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "Termination_date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Employee_name",
        type: "derivedFields",
        placeholder: "Employee Name",
        label: "Employee Name",
        width: "120px",
        addFormOrder: 39,
        editFormOrder: 39,
        stringType: "string",
        header: "Employee Name",
        derivedValue: "Employee_name=FirstName+LastName",
        capitalizeTableText: false,
        sortable: false,
        actions: ["FirstName", "LastName"],
        actionsNumber: [],
        id: "Employee_name",
        displayinaddForm: false,
        displayineditForm: false,
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Employee_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Bank_name",
        type: "text",
        placeholder: "Bank Name",
        label: "Bank Name",
        width: "130px",
        addFormOrder: 37,
        editFormOrder: 41,
        header: "Bank Name",
        derivedValue: "Bank_name=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Bank_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Bank_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "BranchName",
        type: "text",
        placeholder: "Branch Name",
        label: "Branch Name",
        width: "130px",
        addFormOrder: 38,
        editFormOrder: 42,
        header: "Branch Name",
        derivedValue: "BranchName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "BranchName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "BranchName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "attach_file",
        type: "profile",
        placeholder: "Attach File",
        label: "Attach File",
        width: "120px",
        addFormOrder: 39,
        editFormOrder: 43,
        header: "Attach File",
        derivedValue: "attach_file=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "attach_file",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "employee",
        textAlign: "Center",
        show: true,
        field: "attach_file",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 40,
        editFormOrder: 44,
        header: "Status",
        derivedValue: "Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "Status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
      },
      {
        name: "pan",
        type: "text",
        placeholder: "PAN",
        label: "PAN",
        width: "130px",
        addFormOrder: 43,
        editFormOrder: 43,
        header: "PAN",
        derivedValue: "pan=pan=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "pan",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[a-zA-Z0-9]{0,10}$",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "10",
          alphabetsRequired: true,
          showAlphaField: true,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "pan",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
    ];
    return data;
  };

  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

  };

  const getChangedRoleOptions = () => {
    let fields = getFormFields();
    if (fields && fields.length > 0) {
      for (let obj of fields) {
        if (obj.name == "role") {
          obj.options = rolesList && rolesList.length > 0 ? rolesList : [];
        }
      }
    }
    return fields;
  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.employees}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            getDataFromServer();
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  //get monthly data of all employees based on leaves
  const getMonthlyReport = async () => {
    await fetchMethodRequest('GET', 'employees/employeeAttendance')
      .then(async (response) => {
        if (response) {
          const workbook = new ExcelJS.Workbook();

          const headers = [
            { label: 'Employee ID', key: 'employeeId' },
            { label: 'Employee Name', key: 'employeeName' },
            { label: 'Employment Type', key: 'employmentType' },
            { label: 'JoinDate', key: 'joinDate' },
            { label: 'LeaveBalance', key: 'leaveBalance' },
            { label: 'LeaveDates', key: 'leaveDates' },
            { label: 'TotalLeaves', key: 'totalLeaves' },
            { label: 'Total WorkingDays', key: 'totalworkingDays' },
            { label: 'Employee WorkingDays', key: 'employeeWorkingDays' },
            { label:'Timesheet Filled Days', key:'attendanceCount'}
          ];

          // Function to format a row based on headers
          const formatRow = (employee) => headers.map(header => {
            let value = employee[header.key];
            if (header.key === 'joinDate' || header.key === 'leaveDates') {
              if (header.key === 'joinDate') {
                value = moment(value).format('DD-MM-YYYY');
              } else if (header.key === 'leaveDates' && Array.isArray(value)) {
                value = value.map(date => moment(date).format('DD-MM-YYYY')).join('\n');
              }
            }
            return value;
          });

          // Add Current Month sheet
          const sheet1 = workbook.addWorksheet('Current Month');
          sheet1.addRow(headers.map(header => header.label));
          response.currentMonth.forEach(employee => sheet1.addRow(formatRow(employee)));

          // Add Previous Month sheet
          const sheet2 = workbook.addWorksheet('Previous Month');
          sheet2.addRow(headers.map(header => header.label));
          response.previousMonth.forEach(employee => sheet2.addRow(formatRow(employee)));

          // Save the workbook to a blob
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          // Using FileSaver to trigger the download
          saveAs(blob, 'Report.xlsx');
        }
      }).catch((error) => {
        return error;
      })
  }

  const openLeaveUpdateModal = (item) => {
    setLeaveData(item)
    setIsOpenLeaveBalanceModal(true)
  }

  const closeLeaveUpdateModal = () => {
    setIsOpenLeaveBalanceModal(false)
  }

  //Displaying custom action icon for leave balance 
  const customLeavesButton = (item, getData, filters) => {

    return (
      <>
        {loginRole.roleType == 'Admin' ?
          <FontAwesomeIcon
            className='genderIconAlignment'
            color='white'
            icon='check-circle'
            data-toggle="tool-tip"
            title="Leaves"
            style={{ color: '#024a88', width: '15', marginRight: '10px' }}
            onClick={() => openLeaveUpdateModal(item)}
          />
          : ''}
      </>
    )
  }

  const isEditPermission = rolePermission?.type == "Edit" ? true : false
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "Active",
      action: saveDataToServer,
      options: [
        {
          label: "Active",
          field: "status",
          value: "Active",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "Pending",
      action: saveDataToServer,
      options: [
        {
          label: "Pending",
          field: "status",
          value: "Pending",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "InActive",
      action: saveDataToServer,
      options: [
        {
          label: "InActive",
          field: "status",
          value: "InActive",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: true,
        },
      ],
    },
  ]

  return (
    <span>
      {gotRoles && (
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getChangedRoleOptions}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          printRequried={true}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          sampleFilePath={filePath.employees}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          globalSearch={
            "name/email/address/role/FirstName/LastName/CompanyEmail/Consultancy/PreviousExperience/PreviousCompany/EmploymentType/TemporaryAddress/PermanentAddress/EmployeeDocumentDriveLink/Bank_name/BranchName/Status/pan"
          }
          displayName="Employees"
          type="Employees"
          routeTo={apiCalls.employees}
          sideFormLeftOrRight="right"
          displayViewOfForm="sideForm"
          apiResponseKey={apiCalls.employees}
          apiUrl={apiCalls.employees}
          selectedId={params.id}
          customLeavesButton={customLeavesButton}
          defaultCriteria={[
            { key: "status", value: ["Active", "Pending"], type: "in" },
          ]}
          getMonthlyReport={getMonthlyReport}
        />
      )}
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="employees"
          apiUrl={apiCalls.employees}
        />
      ) : null}
      {isOpenLeaveBalanceModal ? (
        <LeaveBalanceModal
          leaveData={leaveData}
          type={'timeSheets'}
          isOpenLeaveBalanceModal={isOpenLeaveBalanceModal}
          closeLeaveUpdateModal={closeLeaveUpdateModal}
        />
      ) : null}
    </span>
  );
};

export default Employees;
