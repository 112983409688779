import React from "react";
import { InputText } from 'primereact/inputtext';

const InputField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : '';
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;

    return (
        <div className="flex flex-column">
            <label htmlFor={id} className="text-capitalize">{label}</label>
            <InputText
                type={type}
                value={field.value}
                id={id}
                name={name}
                placeholder={placeholder}
                aria-describedby="username-help"
                onChange={(e) => field.onChange(e.target.value)} />
            {props.fieldState.invalid ? props.errors[props.name]?.message : ''}

        </div>
    )

}

export default InputField;