import React from "react";
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoSelectField = (props) => {

  const decideOptionsToShow =()=>{

    if(((props.formType=="edit" || props.formType=="view") && name=="taskStatus" && props.screenName=="Tasks" && props.getValues('taskStatus')=="review completed")  ||  (name=="status" && props.screenName=="Sprints" && (props.getValues('status')=="Dev Completed" || props.getValues('status')=="Completed"))){
      return props.item.altOptions;
    }

    else if((name=="status" && props.screenName=="Sprints" && props.formType=="add")  ||  (name === "taskStatus" && props.screenName === "Tasks" && props.getValues('taskStatus') === "ready for testing")){
      let opts;
      if(props.screenName=="Sprints"){
        opts = props.options.filter(option => option.label !== "Dev Completed");
      }
      else{
        opts = [...props.options, { label: "Ready For Testing", value: "ready for testing", color: "warning" }];
      }
      return opts;
    }

    else
      return props.options;

  }

  const id = props.id ? props.id : props.name;
  const label = props.label ? props.label : props.name;
  const optionLabel = props.optionLabel ? props.optionLabel : 'label';
  const name = props.name;
  const options = decideOptionsToShow();
  const placeholder = props.placeholder;
  let field = props.field;

  let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
      />
    );
  };

  const onChange = (e) => {
    
    field.onChange(e.value)
    
    if(props.screenName=="Tasks"){
      props.checkLinkedTaskStatus();
    }

    if((props.formType=="edit" || props.formType=="view") && props.screenName=="Sprints" && e.target.name=="status"){
      if(e.value=="Completed" || e.value=="Dev Completed"){
      props.handleSprintReportData("true",e.value);
      }
      else{
        props.handleSprintReportData("false");
      }
    }

    // if(props.onChange){
    //   props.onChange(e.value)
    // }
    if ((props.screenName == 'Roles' && name == 'roleType') || props.item.dependent) {
      props.handleFnEnableControlsBasedOnValue(e.value, props.item.dependent)
    }
    if (e.value !== 'Assigned' && props.screenName == 'Inventories') {
      props.setValue("assignedTo", '')
    }
  }

  return (
    <div className="flex flex-column ">
      <div className="flex align-center"> {/* Added a wrapper div with flex class */}
        <label htmlFor={id} className="text-capitalize">{label}</label>
        {props.markReq && props.markReq === true && markRequired()}
      </div>
      <Dropdown
        value={field.value}
        optionLabel={optionLabel}
        placeholder={placeholder}
        name={name}
        filter={name === 'assignedTo' ? true : false}
        options={options}
        focusInputRef={field.ref}
        // onChange={(e) => field.onChange(e.value)}
        onChange={onChange}

      />
      <small className="text-danger " style={{ marginTop: "5px" }}>{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
    </div>
  )

}

export default DoSelectField;