import React from "react";
import { InputTextarea } from 'primereact/inputtextarea';

const DoTextareaField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : '';
    const name = props.name;
    const rows = props.rows;
    const cols = props.cols;
    let field = props.field;
    const placeholder = props.placeholder;

    return (
        <div className="flex flex-column">
            <label htmlFor={field.name}>{label}</label>
            <InputTextarea
                id={field.name}
                {...field}
                rows={rows ? rows : 2}
                cols={cols}
            />


        </div>
    )

}

export default DoTextareaField;

